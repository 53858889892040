import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import logoMain from '../img/tasaciones_logo.png';
import logoLetter from '../img/tasacionesya_letter.png';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa'; // Import icons from react-icons

const Navbar = ({ status, setModalLogin, isAuthenticated, setIsAuthenticated }) => {
    const navigate = useNavigate(); // Initialize navigate
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle the hamburger menu
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // State to handle mobile view

    useEffect(() => {
        // Function to update the `isMobile` state based on window width
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Attach the resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLoginClick = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        setModalLogin(true); // Set modal login state to true
    };

    const handleLogout = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        localStorage.removeItem('userToken'); // Remove the token from local storage
        setIsAuthenticated(false); // Update state to reflect that the user is no longer authenticated
        navigate('/'); // Redirect to the main page or login page
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the menu open/closed
    };

    // Define the color codes
    const primaryColor = '#8360C3'; // Purple color
    const secondaryColor = '#2EBF91'; // Green color

    // Inline styles
    const styles = {
        navbar: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#fff', // Background color for the navbar
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
            borderBottom: `1px solid ${primaryColor}`, // Border color to match primary color
            width: '100%', // Ensure the navbar spans the full width
            boxSizing: 'border-box', // Include padding and border in the element's total width and height
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            flex: isMobile ? '1' : 'none', // Take up space only on mobile
            justifyContent: isMobile ? 'center' : 'flex-start', // Center on mobile
        },
        letterContainer: {
            display: isMobile ? 'none' : 'flex', // Hide on mobile
            flex: 1,
            justifyContent: 'center', // Center the letter image on larger screens
        },
        loginLinkContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        logoImage: {
            height: '40px', // Adjust the height as needed
        },
        letterImage: {
            height: '40px', // Adjust the height as needed
        },
        loginLink: {
            textDecoration: 'none',
            color: primaryColor, // Use primary color for the login link
            marginLeft: '10px',
            paddingRight: '30px', // Add right padding to the login link
            display: 'flex',
            alignItems: 'center',
        },
        loginIcon: {
            marginRight: '5px', // Space between the icon and text
        },
        hamburgerMenu: {
            display: isMobile ? 'block' : 'none', // Show on mobile only
            fontSize: '24px',
            cursor: 'pointer',
            color: primaryColor, // Set color to match the tasacionesLetter image
        },
        menuOpen: {
            display: isMenuOpen && isMobile ? 'block' : 'none', // Show when menu is open and on mobile
            position: 'absolute',
            top: '60px',
            right: '10px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            borderRadius: '4px',
            zIndex: '1000',
            width: '200px', // Fixed width for mobile menu
            boxSizing: 'border-box',
        },
        menuItem: {
            display: 'block',
            padding: '10px',
            textDecoration: 'none',
            color: secondaryColor, // Use secondary color for menu items
        },
        desktopOnly: {
            display: isMobile ? 'none' : 'flex', // Show on desktop only
            alignItems: 'center',
        },
    };

    return (
        <div style={styles.navbar}>
            {/* Conditional rendering for mobile and desktop views */}
            <div style={styles.logoContainer}>
                <img 
                    src={logoMain} 
                    alt="Logo" 
                    style={styles.logoImage}
                />
            </div>
            <div style={styles.letterContainer}>
                <img 
                    src={logoLetter} 
                    alt="Tasaciones Letter" 
                    style={styles.letterImage}
                />
            </div>
            <div 
                style={styles.hamburgerMenu} 
                onClick={toggleMenu}
            >
                {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div style={styles.desktopOnly}>
                {isAuthenticated ? (
                    <a href="#" style={styles.loginLink} onClick={handleLogout}>Cerrar sesión</a>
                ) : (
                    <a href="#" style={styles.loginLink} onClick={handleLoginClick}>
                        <FaUser style={styles.loginIcon} />
                        Ingresar
                    </a>
                )}
            </div>
            {isMenuOpen && (
                <div style={styles.menuOpen}>
                    {isAuthenticated ? (
                        <a href="#" style={styles.menuItem} onClick={handleLogout}>Cerrar sesión</a>
                    ) : (
                        <a href="#" style={styles.menuItem} onClick={handleLoginClick}>
                            <FaUser style={styles.loginIcon} />
                            Ingresar
                        </a>
                    )}
                </div>
            )}
        </div>
    );
};

export default Navbar;
