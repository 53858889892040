
import React, { useEffect, useRef, useState  } from "react";
import { Autocomplete, GoogleMap, useJsApiLoader, Marker, Polygon  } from "@react-google-maps/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';
import logoMain from './img/inmoapp_logo.png';
import sampleImage from './img/sample.JPG';
import { RotatingLines } from 'react-loader-spinner';
import CurrencyFormat from 'react-currency-format';
import wpp_png from "./img/wpp.png";
import ModalCredits from './components/ModalCredits.js';
import ServiceModal from './components/ServiceModal.js';
import ModalLogin from './components/ModalLogin.js';
import SampleResultModal from './components/SampleResultModal.js';
//import PayTasacion from './components/PayTasacion.js';
import Navbar from './components/Navbar.jsx';
import { verifyToken } from './utils/auth';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCar, faRuler, faCogs, faCalendarAlt, faPhone, faEnvelope, faQuestionCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const libraries = ['places','geometry'];

function App() {

  const navigate = useNavigate(); // Initialize navigate

  const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyD9E7iaceHctltR_c_xL8dXbQo1OoFfMw8',
		libraries
	})

  //var url = "http://127.0.0.1";
  var url = "https://tasacionesya.com";

  function request_wpp(){
    fetch(url+':8099/api/request_wpp_tasacionesya').then(function (response) {
}).catch(function (err) {
    //console.log(err);
});
}

  const [marker, setMarker] = useState({ lat: -34.6037345, lng: -58.3841453 });
  const [markers, setMarkers] = useState([]);
  const [coordsArray, setCoordsArray] = useState([]);

  const [address, setAddress] = useState();
  const [addressD, setAddressD] = useState();
  const [zone, setZone] = useState("");
  const [operation, setOperation] = useState("alquiler");
  const [property, setProperty] = useState("departamento");
  const [amb, setAmb] = useState(1);
  const [garage, setGarage] = useState(0);
  const [sc, setSc] = useState(0);
  const [st, setSt] = useState(0);
  const [sd, setSd] = useState(0);
  const [sterre, setSterreno] = useState(0);
  const [condition, setCondition] = useState("Estrenar");
  const [antiguedad, setAntiguedad] = useState(1);
  const [barbecue, setBarbecue] = useState(false);
  const [pool, setPool] = useState(false);
  const [sum, setSum] = useState(false);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [valorProp, setValorProp] = useState(0);
  const [valorPropUno, setValorPropUno] = useState(0);
  const [valorPropDos, setValorPropDos] = useState(0);
  const [gotPrice, setGotPrice] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [user, setUser] = useState('');
  const [motive, setMotive] = useState('Inmobiliaria');
  const [modalC, setModalC] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [logedIn, setLogIn] = useState(false);
  const [isModalSampleOpen, setIsModalSampleOpen] = useState(false);
  const [isPayTasacionOpen, setIsPayTasacionOpen] = useState(false);
  const [foundZone, setFoundZone] = useState(true);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);

  

  const openPayTasacionModal = () => {
    setIsPayTasacionOpen(true);
};

const closePayTasacionModal = () => {
    setIsPayTasacionOpen(false);
};


  const openModalSample = () => setIsModalSampleOpen(true);
  const closeModalSample = () => setIsModalSampleOpen(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  //const userLogin = queryParams.get('user_login');
  //const usrNameU = queryParams.get('user');
  const [userLoginUrl, setUserLoginUrl] = useState(queryParams.get('user_login'));
  const [usrNameU, setUsrNameU] = useState(queryParams.get('user'));
  const [usrPu, setUsrPU] = useState(queryParams.get('token'));

  useEffect(() => {
    if (isModalSampleOpen == false && userLoginUrl === 'true') {
      //onHide(); // Show the modal if user_login=true
      setModalLogin(true);
      //console.log("Login modal");
    }else{
      //setIsModalSampleOpen(true);
      /*
      const timer = setTimeout(() => {
        setIsModalSampleOpen(true);
      }, 4000);

      // Cleanup timeout if the component unmounts or dependencies change
      return () => clearTimeout(timer);
      */
    }
  },[userLoginUrl]);

  //console.log(userLogin);
    

  const a = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setUser(res.data.ip);
    visitor(res.data.ip);
  };


  useEffect(() => {
    a();
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await verifyToken(); // Await the promise
      if (!authenticated) {
        //navigate('/vendor'); // Redirect to the main page if not authenticated
        //console.log("Invalid token");
      } else {
        //console.log("Valid token");
        navigate('/vendor');
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    // Clear results when operation changes
    setGotPrice(false);
    setValorProp(0);
    setValorPropUno(0);
    setValorPropDos(0);
  }, [operation]);

  const handleChange = (event,num) => {

    const inputValue = event.target.value;

    if(num=="amb"){
      if (/^\d*$/.test(inputValue)) {
        setAmb(inputValue);
      }
    }else if(num=="garage"){
      if (/^\d*$/.test(inputValue)) {
        setGarage(inputValue);
      }
    }else if(num=="sc"){
      if (/^\d*$/.test(inputValue)) {
        setSc(inputValue);
      }
    }else if(num=="st"){
      if (/^\d*$/.test(inputValue)) {
        setSt(inputValue);
      }
    }else if(num=="sd"){
      if (/^\d*$/.test(inputValue)) {
        setSd(inputValue);
      }
    }else if(num=="sterre"){
      if (/^\d*$/.test(inputValue)) {
        setSterreno(inputValue);
      }
    }else if(num=="antiguedad"){
      if (/^\d*$/.test(inputValue)) {
        setAntiguedad(inputValue);
      }
    }
    

    // Check if the input value is a valid integer
    
  };

  const get_price = () => {
    setLoadingPrice(true);
    axios({
      method: 'POST',
      url: url + ':8099/api/get_price_11',
      data: {
        address: address,
        addressD: addressD,
        zone_c: zone,
        operation: operation,
        property: property,
        amb: amb,
        garage: garage,
        sc: sc,
        st: st,
        sd: sd,
        sterre: sterre,
        condition: condition,
        antiguedad: antiguedad,
        barbecue: barbecue,
        pool: pool,
        sum: sum,
        email: email,
        phone: phone,
        motive: motive,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setGotPrice(false);
          setModalC(true); // Show the credits modal if there's an issue
        } else {
          setValorProp(res.data.valor);
          setValorPropUno(res.data.averagePriceARS || res.data.valorPropUno);
          setValorPropDos(res.data.averagePriceUSD || res.data.valorPropDos);
          //setGotPrice(true);
          setModalC(true);
          //setIsPriceModalOpen(true); // Open the price modal
        }
        setLoadingPrice(false);
      })
      .catch((error) => {
        setLoadingPrice(false);
        setModalC(true); // Handle errors gracefully
      });
  };
  

const visitor = (a) => {
  axios({
    method:'POST',
    url: url+':8099/api/visitor',
    data:{
      address: a
    }
}).then(res =>  {
}).catch(error => {
});
}

const send = (a) => {
  axios({
    method:'POST',
    url: url+':8099/api/send_a',
    data:{
      address: a
    }
}).then(res =>  {
}).catch(error => {
});
}

useEffect(() => {
  if(gotPrice){
    scrollToBottom();
  }
},[valorProp])

const scrollToBottom = () => {
  const element = document.getElementById("result");
  element.scrollIntoView();
}

/*
const onMapClick = (e) => {
  setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    ]);
    setCoordsArray(coordsArray+"{ lat: "+e.latLng.lat()+", lng: "+e.latLng.lng()+"},");
  };
  */


	const mapRef = useRef();
	const autocompleteRef = useRef();

  const styles = {
    formContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        paddingBottom: '10px'
    },
    buttonGroup: {
        display: 'flex',
        gap: '10px'
    },
    buttonAlqventa: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        fontSize: '16px',
        borderRadius: '5px',
        transition: 'background-color 0.3s'
    },
    buttonAlqventaPrimary: {
        backgroundColor: '#2EBF91' // Primary button color
    },
    buttonAlqventaPrimaryHover: {
        backgroundColor: '#0056b3' // Darker color on hover
    },
    buttonAlqventaSecondary: {
        backgroundColor: '#6c757d' // Secondary button color
    },
    buttonAlqventaSecondaryHover: {
        backgroundColor: '#5a6268' // Darker color on hover
    },
    icon: {
        marginRight: '5px'
    },
    label: {
        fontWeight: 'bold',
        padding: '10px',
        color: 'white'
    }
};

const stylesForm1 = `
  .form-container {
    padding: 20px;
    border-radius: 8px;
    color: white;
  }
  .form-label {
    font-weight: bold;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
  }
  .form-label i {
    padding-right: 12px;
  }
  .form-control {
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  .form-group1 {
    margin-bottom: 1rem;
  }
  .form-group1.hidden {
    display: none;
  }
`;

	const containerStyle = {
        width: "95%",
        height: "100vh",
        margin:"auto",
        paddingTop: "10px",
        paddingRight:"0px",
        borderRadius:"20px",
        marginTop:"8%"
    }
    const mapContainerStyle = {
      width: "95%",
      height: "50vh",
      margin:"auto",
      paddingTop: "10px",
      paddingRight:"0px",
      borderRadius:"20px",
      marginTop:"8%"
  }

	const inputStyle = {
		boxSizing: 'border-box',
		border: '1px solid transparent',
		width: '240px',
		height: '38px',
		padding: '0 12px',
		borderRadius: '3px',
		boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
		fontSize: '14px',
		outline: 'none',
		textOverflow: 'ellipses',
		position: 'absolute',
		right: '8%',
		top: '11px',
		marginLeft: '-120px'
	}
  const buttonStyle = {
    backgroundColor: '#8360C3', // Primary color
    background:'none',
    width:'100%',
    border: 'none',
    color: 'white',
    padding: '12px 24px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  };
  
  const buttonHoverStyle = {
    backgroundColor: '#2EBF91', // Secondary color
    transform: 'scale(1.05)',
  };

    const center = {
        lat: 28.626137,
        lng: 79.821603,
    }

	const onLoadMap = (map) => {
		mapRef.current = map
	}

	const onLoadAutocomplete = (autocomplete) => {
		autocompleteRef.current = autocomplete
	}

  const checkArea = (zone) => {
  }

  const TasarBtn = () => {
    if(!loadingPrice){
      return(
        <div
      style={buttonStyle}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
    >
      Valuar
    </div>
      )
    }else{
      return(
        <div><RotatingLines
        visible={true}
        height="45"
        width="45"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
        /></div>
      )
    }
  }

	const handlePlaceChanged = async ()  => {
		const { geometry, formatted_address, results } = await autocompleteRef.current.getPlace();
    var zone = await JSON.stringify(JSON.stringify(autocompleteRef.current.getPlace().address_components));

    setZone(zone);
    setAddressD(formatted_address);
    send(formatted_address);
		const bounds = new window.google.maps.LatLngBounds();
		if (geometry.viewport) {
			bounds.union(geometry.viewport);
		} else {
			bounds.extend(geometry.location);
		}
		mapRef.current.fitBounds(bounds);
    setMarker(geometry.location);
    setAddress(geometry.location);
  }

  const safeValue = value => value ? value : 0;

  const containsLoc = [];

	return (
    <>
    <Navbar status={modalLogin} setModalLogin={setModalLogin} setModalC={setModalC}  />
    
		<div style={{margin:'auto',width:'95%',padding:'0px',backgroundColor:''}}>
        {
				isLoaded
				&&
        <div style={{margin:'auto',width:'100%',padding:'0px'}}>
        
         <div style={{padding:'20px',textAlign:'center'}}>
         <h2 style={{textAlign:'center'}}>GBA y CABA</h2>
         <h3 style={{display:'none'}}>Tasacion online <b style={{color:'#5af542',fontStyle:'italic',textAlign:'center',display:'none'}}>AL INSTANTE </b></h3>
         <div className="col-lg-12" style={{textAlign:'center',margin:'auto',padding:'10px',fontStyle:'italic',display:'none'}}>Tasador creado por <a href="https://srrealty.group">Sr Realty Group (Matricula CMCPSI7003 - CUCICBA8392)</a></div>
        
         </div>
         
        <div className="row mainMenu" style={{margin:'auto',backgroundColor:'#2355c6'}}>
        <div className="col-lg-6">
        <Autocomplete
						onLoad={onLoadAutocomplete}
						onPlaceChanged={handlePlaceChanged}
					>
            <div style={{margin:'center',textAlign:'center',padding:'20px',width:'100%'}}>
            <label for="" style={{fontWeight:"bold",padding:'10px',color:'white',width:'100%'}}>Ingresar la direccion</label>
            <input
            style={{padding:'10px',width:'100%',borderRadius:'10px',outline:'none',borderColor:'lightblue', borderStyle:'solid'}}
							type='text'
							placeholder='Ingresa tu direccion'
						/>
            </div>
						
					</Autocomplete>
          <ModalCredits status={modalC} setModalC={setModalC} url={url} found={foundZone} price1={valorPropUno} price2={valorPropDos}  />
          <ModalLogin status={modalLogin} setModalLogin={setModalLogin} url={url} username={usrNameU} password={usrPu} />
          <ServiceModal status={true} url={url} />
          <SampleResultModal 
          isOpen={isModalSampleOpen} 
          onClose={closeModalSample} 
          imageSrc={sampleImage} 
          onRegister={setModalC}
          />
        
          <div className="row" style={{paddingBottom:'10px'}}>

          
            <div className="col-12 col-sm-12 col-lg-6">
            
                <label htmlFor="operacion" style={styles.label}>
                    Operacion
                </label>
                <div style={styles.formContainer}>
                <div style={styles.buttonGroup}>
                    <button
                        id="rent-button"
                        style={{ ...styles.buttonAlqventa, ...(operation === 'alquiler' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setOperation('alquiler')}
                    >
                        <i className="fas fa-house-user" style={styles.icon}></i> Alquiler
                    </button>
                    <button
                        id="sale-button"
                        style={{ ...styles.buttonAlqventa, ...(operation === 'venta' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setOperation('venta')}
                    >
                        <i className="fas fa-home" style={styles.icon}></i> Venta
                    </button>
                </div>
                </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-6">
            
                <label htmlFor="propiedad" style={styles.label}>
                    Tipo de Propiedad
                </label>
                <div style={styles.formContainer}>
                <div style={styles.buttonGroup}>
                    <button
                        id="department-button"
                        style={{ ...styles.buttonAlqventa, ...(property === 'departamento' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setProperty('departamento')}
                    >
                        <i className="fas fa-building" style={styles.icon}></i> Departmento
                    </button>
                    <button
                        id="house-button"
                        style={{ ...styles.buttonAlqventa, ...(property === 'casa' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setProperty('casa')}
                    >
                        <i className="fas fa-home" style={styles.icon}></i> Casa
                    </button>
                </div>
                </div>
            </div>
        
         
            
          {
            operation==='alquiler' ? 
            <>
    <div className="form-container">
    <div className="row">
    <div className="col-lg-6 form-group1">
    <style>{stylesForm1}</style>
          <label htmlFor="ambientes" className="form-label">
            <FontAwesomeIcon icon={faHome} /> <b style={{paddingLeft:'8px'}}>Ambientes</b>
          </label>
          <input 
            type="text" 
            id="ambientes" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={amb} 
            onChange={(e) => handleChange(e, "amb")} 
          />
        </div>
        <div className="col-lg-6 form-group1">
          <label htmlFor="cochera" className="form-label">
            <FontAwesomeIcon icon={faCar} /> <b style={{paddingLeft:'8px'}}>Cochera/s (cantidad)</b>
          </label>
          <input 
            type="text" 
            id="cochera" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            placeholder="0" 
            value={garage || ''} 
            onChange={(e) => handleChange(e, "garage")} 
          />
        </div>
        <div className="col-lg-6 form-group1">
        <label htmlFor="antiguedad" className="form-label">
            <FontAwesomeIcon icon={faCalendarAlt} /> <b style={{paddingLeft:'8px'}}>Antigüedad (años)</b>
          </label>
          <input 
            type="text" 
            id="antiguedad" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={antiguedad} 
            onChange={(e) => handleChange(e, "antiguedad")} 
          />
        </div>
        <div className="col-lg-12 form-group1" style={{display:'none'}}>
          <label htmlFor="email" className="form-label">
            <FontAwesomeIcon icon={faEnvelope} /><b style={{paddingLeft:'8px'}}>Correo electrónico</b> 
          </label>
          <input 
            type="text" 
            id="email" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        </div>
        </div>
  </>
          :
          <>
          
          <div className="form-container">
      <style>{stylesForm1}</style>
      <div className="row">
        <div className="col-lg-6 form-group1">
          <label htmlFor="ambientes" className="form-label">
            <FontAwesomeIcon icon={faHome} /> <b style={{paddingLeft:'8px'}}>Ambientes</b>
          </label>
          <input 
            type="text" 
            id="ambientes" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={amb} 
            onChange={(e) => handleChange(e, "amb")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="cochera" className="form-label">
            <FontAwesomeIcon icon={faCar} /> <b style={{paddingLeft:'8px'}}>Cochera/s (cantidad)</b>
          </label>
          <input 
            type="text" 
            id="cochera" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            placeholder="0" 
            value={garage || ''} 
            onChange={(e) => handleChange(e, "garage")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="sc" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Cubierta m²</b>
          </label>
          <input 
            type="text" 
            id="sc" 
            className="form-control" 
            aria-describedby="sc" 
            placeholder="0" 
            value={sc || ''} 
            onChange={(e) => handleChange(e, "sc")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="st" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Semicubierta m²</b>
          </label>
          <input 
            type="text" 
            id="st" 
            className="form-control" 
            aria-describedby="st" 
            placeholder="0" 
            value={st || ''} 
            onChange={(e) => handleChange(e, "st")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="sd" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Descubierta m²</b>
          </label>
          <input 
            type="text" 
            id="sd" 
            className="form-control" 
            aria-describedby="sd" 
            placeholder="0" 
            value={sd || ''} 
            onChange={(e) => handleChange(e, "sd")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="estado" className="form-label">
            <FontAwesomeIcon icon={faCogs} /> <b style={{paddingLeft:'8px'}}>Estado</b>
          </label>
          <select 
            id="estado" 
            className="form-control" 
            aria-describedby="estado" 
            defaultValue={"Estrenar"} 
            onChange={(e) => setCondition(e.target.value)}
          >
            <option value={"Estrenar"}>Estrenar</option>
            <option value={"Muy bueno"}>Muy bueno</option>
            <option value={"Bueno"}>Bueno</option>
            <option value={"Regular"}>Regular</option>
            <option value={"Malo"}>Malo</option>
            <option value={"Restauracion"}>Restauracion</option>
            <option value={"Demolicion"}>Demolicion</option>
          </select>
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="antiguedad" className="form-label">
            <FontAwesomeIcon icon={faCalendarAlt} /> <b style={{paddingLeft:'8px'}}>Antigüedad (años)</b>
          </label>
          <input 
            type="text" 
            id="antiguedad" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={antiguedad} 
            onChange={(e) => handleChange(e, "antiguedad")} 
          />
        </div>

        <div className="col-lg-6 form-group1 hidden">
          <label htmlFor="celular" className="form-label">
            <FontAwesomeIcon icon={faPhone} /> Celular
          </label>
          <input 
            type="text" 
            id="celular" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            onChange={(e) => setPhone(e.target.value)} 
          />
        </div>

        <div className="col-lg-12 form-group1" style={{display:'none'}}>
          <label htmlFor="email" className="form-label">
            <FontAwesomeIcon icon={faEnvelope} /><b style={{paddingLeft:'8px'}}>Correo electrónico</b> 
          </label>
          <input 
            type="text" 
            id="email" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>

        <div className="col-lg-12 form-group1 hidden">
          <label htmlFor="motive" className="form-label">
            <FontAwesomeIcon icon={faQuestionCircle} /> ¿Motivo de tasación?
          </label>
          <select 
            id="motive" 
            className="form-control" 
            aria-describedby="estado" 
            value={motive} 
            onChange={(e) => setMotive(e.target.value)}
          >
            <option value={"Inmobiliaria"}>Inmobiliaria</option>
            <option value={"Escribania"}>Escribania</option>
            <option value={"Personal"}>Personal</option>
          </select>
        </div>

        <div className="col-lg-12 form-group1 hidden">
          <label htmlFor="contacto" className="form-label">
            <FontAwesomeIcon icon={faHandshake} /> ¿Estás pensando en vender o alquilar tu propiedad y te gustaría que un agente se contacte?
          </label>
        </div>
      </div>
    </div>
            
          </>
          }
          </div>
          
          <label for="amenities" style={{fontWeight:"bold",padding:'10px',color:'white',display:'none'}}>Amenities</label>
          <div className="row" style={{margin:'auto',textAlign:'center'}}>
          <div style={{display:'none'}}>
          <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              Parrilla <input type="checkbox" onChange={(e) => {setBarbecue(!barbecue)}} />
            </div>
            <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              Pileta <input type="checkbox" onChange={(e) => {setPool(!pool)}} />
            </div>
            <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              SUM <input type="checkbox" onChange={(e) => {setSum(!sum)}} />
            </div>
            </div>
           
            
            <div className="col-lg-12"><button className={""} onClick={e => get_price()} disabled={!address || loadingPrice} ><TasarBtn /></button></div>
            
          </div>
          
        </div>
        <div className="col-lg-6">
        
          <GoogleMap
					mapContainerStyle={mapContainerStyle}
					center={marker}
					onLoad={onLoadMap}
          //onClick={onMapClick}
					zoom={15}
				>
            <Marker 
              position={marker} 
            />

            {markers.map((marker) => (
            <Marker 
              position={{ 
              lat: marker.lat,
              lng: marker.lng 
            }} />
            ))}
          
				</GoogleMap>
        {coordsArray}
        </div>
        
        </div>
        {
  gotPrice && operation === 'alquiler' ? (
    <div id={'result'} className="row mainMenu" style={{textAlign:'center',margin:'auto',borderColor:'#0e4d92',borderStyle:'solid',marginTop:'20px',background:'none',backgroundColor:"white"}}>
        <div>{addressD}</div>
        <div className="col-lg-12">
          <div style={{textAlign:'center',margin:'auto'}}>
            <h3>Valor aproximado <b style={{color:'blue'}}>AR$ {valorPropUno}</b>
            </h3>
            <h3>Valor aproximado <b style={{color:'green'}}>U$D {valorPropDos}</b></h3>          </div>
          <div className="col-lg-12" style={{textAlign:'center',margin:'auto',padding:'10px',fontStyle:'italic'}} onClick={request_wpp}>
          </div>
        </div> 
      </div> // Render an empty div if gotPrice is true and operation is 'Alquiler'
  ) : (
    gotPrice && (
      <div id={'result'} className="row mainMenu" style={{textAlign:'center',margin:'auto',borderColor:'#0e4d92',borderStyle:'solid',marginTop:'20px',background:'none',backgroundColor:"white"}}>
        <div>{addressD}</div>
        <div className="col-lg-12">
          <div style={{textAlign:'center',margin:'auto'}}>
            <h3>Valor aproximado entre  <b style={{color:'green'}}><CurrencyFormat value={safeValue(valorPropUno)} displayType={'text'} thousandSeparator={true} prefix={'U$'} decimalScale={0} /></b> y <b style={{color:'green'}}><CurrencyFormat value={safeValue(valorPropDos)} displayType={'text'} thousandSeparator={true} prefix={'U$'} decimalScale={0} /></b>
            </h3>
          </div>
          <div className="col-lg-12" style={{textAlign:'center',margin:'auto',padding:'10px',fontStyle:'italic'}} onClick={request_wpp}>
          </div>
        </div>
      </div>
    )
  )
}

        <div className={'row'}>
        <div className="col-lg-12" style={{textAlign:'center',margin:'auto', padding:'20px'}}>
          <b>info@tasacionesya.com - <a href="/terminos-y-condiciones">Terminos y Condiciones</a></b>
          <div>Copyright © tasacionesya.com 2024</div>
        </div>
        </div>
        <div className="wpp" onClick={request_wpp}>
                <a href="https://wa.me/+5491151048360"><img src={wpp_png} className="wpp_img" /></a>
        </div>
        </div>
				
			}
		</div>
    {isPriceModalOpen && (
  <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Resultado de Tasación</h5>
          <button type="button" className="btn-close" onClick={() => setIsPriceModalOpen(false)}></button>
        </div>
        <div className="modal-body">
          <p>Dirección: <strong>{addressD}</strong></p>
          <p>
            Valor Aproximado: 
            <ul>
              <li>AR$: <strong>{valorPropUno}</strong></li>
              <li>U$D: <strong>{valorPropDos}</strong></li>
            </ul>
          </p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => setIsPriceModalOpen(false)}>Cerrar</button>
        </div>
      </div>
    </div>
  </div>
)}
    </>
	);

}

export default App;
