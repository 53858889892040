import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import  App  from './App';
import  Admin  from './components/Admin';
import  Vendor  from './components/Vendor';
import  Tasacion  from './components/Tasacion';
import  Alquileres  from './components/Alquileres';
import  TermsAndConditions  from './components/TermsAndConditions'
import  FailedPayment  from './components/FailedPayment'

function Main() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} exact />
                <Route path="/admin" element={<Admin />} exact />
                <Route path="/vendor" element={<Vendor />} exact />
                <Route path="/terminos-y-condiciones" element={<TermsAndConditions />} exact />
                <Route path="/tasacion" element={<Tasacion />} exact />
                <Route path="/failed_payment" element={<FailedPayment />} exact />
            </Routes>
        </Router>
    );
  }

export default Main;