import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faHome, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import logo from '../img/tasaciones_logo.png'; // Ensure this is the correct path to your logo image

const Tasacion = () => {
  const [price1, setPrice1] = useState(null);
  const [price2, setPrice2] = useState(null);
  const [address, setAddress] = useState('');
  const [operation, setOperation] = useState('');
  const resultRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const p1 = queryParams.get('price1');
    const p2 = queryParams.get('price2');
    const addr = queryParams.get('address');
    const op = queryParams.get('operation');

    if (p1) setPrice1(p1);
    if (p2) setPrice2(p2);
    if (addr) setAddress(addr);
    if (op) setOperation(op);
  }, [location.search]);

  const formatPrice = (price) => {
    if (!price) return '';
    // Convert to number and format as string with ',' as decimal separator and no decimals
    return parseFloat(price).toLocaleString('es-AR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const renderResult = () => {
    if (operation === 'venta') {
      return price1 && price2
        ? `Valor estimado entre U$${formatPrice(price1)} y U$${formatPrice(price2)}`
        : 'No se encontraron valores de referencia para la venta';
    } else if (operation === 'alquiler') {
      return (
        <>
          {price1 && <div className="result-item"><FontAwesomeIcon icon={faDollarSign} className="result-icon" /> Valor en AR$ {formatPrice(price1)}</div>}
          {price2 && <div className="result-item"><FontAwesomeIcon icon={faDollarSign} className="result-icon" /> Valor en U$D {formatPrice(price2)}</div>}
          {!price1 && !price2 && 'No se encontraron valores de referencia para el alquiler'}
        </>
      );
    }
    return 'Operación no especificada. Verifique los parámetros de la URL.';
  };

  const handleDownloadPDF = async () => {
    const input = resultRef.current;

    // Take a screenshot of the whole page, excluding the PDF button
    html2canvas(document.body, { ignoreElements: el => el.classList.contains('pdf-button-container') })
      .then(async (canvas) => {
        // Wait for the image to load and adjust scaling if needed
        const imgData = canvas.toDataURL('image/png');
        
        // Create a new PDF document
        const pdfDoc = await PDFDocument.create();
        const [width, height] = [canvas.width, canvas.height]; // Use the full page size
        const page = pdfDoc.addPage([width, height]);
        const pngImage = await pdfDoc.embedPng(imgData);
        page.drawImage(pngImage, { x: 0, y: 0, width, height });

        // Serialize the PDF document to bytes
        const pdfBytes = await pdfDoc.save();
        
        // Trigger the download
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'valuacion_resultado.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up URL object
      });
  };

  return (
    <div className="tasacion-wrapper">
      <div className="tasacion-container" ref={resultRef}>
        <div className="logo-container">
          <img src={logo} alt="Tasaciones Logo" className="logo" />
        </div>
        <h2 className="tasacion-title">Resultado de la Valuacion</h2>
        <div className="tasacion-address">
          <FontAwesomeIcon icon={faHome} className="address-icon" />
          <p className="address-text">{address}</p>
        </div>
        <div className="tasacion-result">
          {renderResult()}
        </div>
      </div>
      <div className="pdf-button-container">
        <button className="pdf-button" onClick={handleDownloadPDF}>
          <FontAwesomeIcon icon={faFilePdf} className="pdf-icon" />
          Descargar en PDF
        </button>
      </div>
      <style jsx>{`
        .tasacion-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px; /* Adjust top padding here */
        }

        .tasacion-container {
          padding: 30px;
          max-width: 600px;
          margin: 0 auto;
          text-align: center;
          background: #ffffff;
          border-radius: 12px;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }

        .logo-container {
          margin-bottom: 20px;
        }

        .logo {
          max-width: 150px; /* Adjust the size as needed */
          height: auto;
        }

        .tasacion-title {
          color: #4A148C; /* Darker purple */
          font-size: 2.5rem;
          font-weight: 700;
          margin-bottom: 20px;
          font-family: 'Roboto', sans-serif;
        }

        .tasacion-address {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          font-size: 1.2rem;
          color: #555;
        }

        .address-icon {
          margin-right: 12px;
          color: #8e24aa;
        }

        .address-text {
          margin: 0;
          font-family: 'Roboto', sans-serif;
        }

        .tasacion-result {
          font-size: 1.2rem;
          color: #333;
          font-family: 'Roboto', sans-serif;
        }

        .result-item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 15px 0;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .result-icon {
          margin-right: 10px;
          color: #4caf50;
        }

        .pdf-button-container {
          margin-top: 20px;
          text-align: center;
        }

        .pdf-button {
          padding: 10px 20px;
          background-color: #8e24aa;
          border: none;
          border-radius: 8px;
          color: #fff;
          font-size: 1.1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Roboto', sans-serif;
        }

        .pdf-button:hover {
          background-color: #6a1b9a;
        }

        .pdf-icon {
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};

export default Tasacion;
