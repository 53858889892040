
import React, { useEffect, useRef, useState  } from "react";
import { Autocomplete, GoogleMap, useJsApiLoader, Marker, Polygon  } from "@react-google-maps/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import logoMain from '../img/inmoapp_logo.png';
import { RotatingLines } from 'react-loader-spinner';
import CurrencyFormat from 'react-currency-format';
import wpp_png from "../img/wpp.png";
import Navbar from '../components/Navbar.jsx';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { verifyToken } from '../utils/auth'; // Import the token verification function
import CustomNavbar from '../components/CustomNavbar.jsx'; // Import the Navbar component
import BuyCredits from '../components/BuyCreditsModal.js';
import PDF from '../components/TasacionPDF.js';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCar, faRuler, faCogs, faCalendarAlt, faPhone, faEnvelope, faQuestionCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';



const libraries = ['places','geometry'];

function Vendor() {
  

  const navigate = useNavigate(); // Initialize navigate

  const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyD9E7iaceHctltR_c_xL8dXbQo1OoFfMw8',
		libraries
	})

  //var url = "http://127.0.0.1";
  var url = "https://tasacionesya.com";

  function request_wpp(){
    fetch('https://tasacionesya.com:8099/api/request_wpp_tasacionesya').then(function (response) {
}).catch(function (err) {
    console.log(err);
});
}

  const [marker, setMarker] = useState({ lat: -34.6037345, lng: -58.3841453 });
  const [markers, setMarkers] = useState([]);
  const [coordsArray, setCoordsArray] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Initialize state for authentication
  const [address, setAddress] = useState();
  const [addressD, setAddressD] = useState();
  const [zone, setZone] = useState("");
  const [operation, setOperation] = useState("alquiler");
  const [property, setProperty] = useState("departamento");
  const [amb, setAmb] = useState(1);
  const [garage, setGarage] = useState(0);
  const [sc, setSc] = useState(0);
  const [st, setSt] = useState(0);
  const [sd, setSd] = useState(0);
  const [sterre, setSterreno] = useState(0);
  const [condition, setCondition] = useState("Estrenar");
  const [antiguedad, setAntiguedad] = useState(1);
  const [barbecue, setBarbecue] = useState(false);
  const [pool, setPool] = useState(false);
  const [sum, setSum] = useState(false);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [valorProp, setValorProp] = useState(0);
  const [valorPropUno, setValorPropUno] = useState(0);
  const [valorPropDos, setValorPropDos] = useState(0);
  const [gotPrice, setGotPrice] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [user, setUser] = useState('');
  const [modalC, setModalC] = useState(true);
  const [modalLogin, setModalLogin] = useState(false);
  const [logedIn, setLogIn] = useState(false);
  const [credits, setCredits] = useState(null);
  const [username, setUsername] = useState(0);
  const [userLname, setUserLname] = useState(0);
  const [userType, setUserType] = useState(0);
  const [showBuyCredits, setShowBuyCredits] = useState(false);

  const handleShowBuyCredits = () => setShowBuyCredits(true);
  const handleCloseBuyCredits = () => setShowBuyCredits(false);

  const handleBuyCredits = () => {
    // Implement the logic to handle credit purchase
    //alert(`Purchased ${pack.credits} credits for $${pack.price}`);
    //handleCloseBuyCredits();
    handleShowBuyCredits();
  };

  const a = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setUser(res.data.ip);
  };

  useEffect(() => {
    a();
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await verifyToken(); // Await the promise
      if (!authenticated) {
        navigate('/'); // Redirect to the main page if not authenticated
        console.log("Invalid token");
      } else {
        //console.log("Valid token");
        setIsAuthenticated(true);
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    // Clear results when operation changes
    setGotPrice(false);
    setValorProp(0);
    setValorPropUno(0);
    setValorPropDos(0);
    //console.log("Changed operation to:", operation);
  }, [operation]);

  const checkAuthAndFetchCredits = async () => {
    try {
      // Check authentication
      const authenticated = await verifyToken();
      if (!authenticated) {
        navigate('/'); // Redirect to login page if not authenticated
      } else {
        setIsAuthenticated(true);

        // Fetch credits if authenticated
        const fetchCredits = async () => {
          try {
            const response = await axios.post('https://tasacionesya.com:8099/api/get_credits', {}, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken')}` // Pass the token in the Authorization header
              }
            });

            if (response.status === 200) {
              setCredits(response.data.credits); // Set the credits state
              if(response.data.credits == 0){ //&& credits != null){
                handleShowBuyCredits();
              }
            } else {
              console.error('Error fetching credits:', response.statusText);
              // Handle specific errors based on status codes or response data
            }
          } catch (error) {
            console.error('Error fetching credits:', error);
            // Handle the error accordingly
          }
        };

        await fetchCredits(); // Call fetchCredits after authentication
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
      navigate('/'); // Redirect to login page on error
    }
  };
  useEffect(() => {
    checkAuthAndFetchCredits();
  }, [navigate]);

  useEffect(() => {
    const checkGetUser = async () => {
      try {
        // Check authentication
        const authenticated = await verifyToken();
        if (!authenticated) {
          navigate('/'); // Redirect to login page if not authenticated
        } else {
          setIsAuthenticated(true);

          // Fetch credits if authenticated
          const getUser = async () => {
            try {
              const response = await axios.post('https://tasacionesya.com:8099/api/get_user', {}, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('userToken')}` // Pass the token in the Authorization header
                }
              });

              if (response.status === 200) {
                setUsername(response.data.username); 
                setUserLname(response.data.userLname);
                setUserType(response.data.userType);
              } else {
                console.error('Error fetching user:', response.statusText);
                // Handle specific errors based on status codes or response data
              }
            } catch (error) {
              console.error('Error fetching user:', error);
              // Handle the error accordingly
            }
          };

          await getUser(); // Call fetchCredits after authentication
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        navigate('/'); // Redirect to login page on error
      }
    };

    checkGetUser();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('userToken'); // Remove the token from local storage
    setIsAuthenticated(false); // Update state to reflect that the user is no longer authenticated
    navigate('/'); // Redirect to the main page or login page
  };

  const handleChange = (event,num) => {

    const inputValue = event.target.value;

    if(num=="amb"){
      if (/^\d*$/.test(inputValue)) {
        setAmb(inputValue);
      }
    }else if(num=="garage"){
      if (/^\d*$/.test(inputValue)) {
        setGarage(inputValue);
      }
    }else if(num=="sc"){
      if (/^\d*$/.test(inputValue)) {
        setSc(inputValue);
      }
    }else if(num=="st"){
      if (/^\d*$/.test(inputValue)) {
        setSt(inputValue);
      }
    }else if(num=="sd"){
      if (/^\d*$/.test(inputValue)) {
        setSd(inputValue);
      }
    }else if(num=="sterre"){
      if (/^\d*$/.test(inputValue)) {
        setSterreno(inputValue);
      }
    }else if(num=="antiguedad"){
      if (/^\d*$/.test(inputValue)) {
        setAntiguedad(inputValue);
      }
    }
    

    // Check if the input value is a valid integer
    
  };

  const get_price = () => {
    setLoadingPrice(true); // Set loading state to true at the start
  
    axios({
        method: 'POST',
        url: `${url}:8099/api/get_price_vendor`,
        data: {
            address,
            addressD,
            zone_c: zone,
            operation,
            property,
            amb,
            garage,
            sc,
            st,
            sd,
            sterre,
            condition,
            antiguedad,
            barbecue,
            pool,
            sum,
            user_i: user
        },
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}` // Pass the token in the Authorization header
        }
    })
    .then(res => {
        const { data } = res;

        // Handle different types of responses based on server response
        if (data.error) {
            switch (data.error) {
                case "err1":
                    setGotPrice(false);
                    alert("Por el momento no cubrimos la zona ingresada. Le estaremos enviando su valuacion una vez que la zona esté cargada. Gracias.");
                    break;
                case "err2":
                    alert("Por favor comuníquese con info@tasacionesya.com si desea continuar usando el servicio.");
                    break;
                case "No tiene créditos disponibles":
                    alert("No tiene más créditos.");
                    setGotPrice(false);
                    setModalC(true);
                    break;
                default:
                    alert("Hubo un error.");
            }
        } else {
            // Handle successful response
            if(operation=='alquiler'){
              setGotPrice(true);
              setValorProp(data.valor);
              setValorPropUno(data.averagePriceARS);
              setValorPropDos(data.averagePriceUSD);
              checkAuthAndFetchCredits();
            }else{
              setGotPrice(true);
              setValorProp(data.valor);
              setValorPropUno(data.valorPropUno);
              setValorPropDos(data.valorPropDos);
              checkAuthAndFetchCredits();
            }
        }

        // Scroll to bottom if price was successfully retrieved
        if (gotPrice) {
            scrollToBottom();
        }

        // Reset loading state
        setLoadingPrice(false);
    })
    .catch(error => {
        console.error("Error fetching price:", error);
        alert("Hubo un error. Por favor revise los datos ingresados.");
        setLoadingPrice(false);
    });
};

  

const send = (a) => {
  axios({
    method:'POST',
    url: 'https://tasacionesya.com:8099/api/send_a',
    data:{
      address: a
    }
}).then(res =>  {
}).catch(error => {
});
}

useEffect(() => {
  if(gotPrice){
    scrollToBottom();
  }
},[valorProp])

const scrollToBottom = () => {
  const element = document.getElementById("result");
  element.scrollIntoView();
}

/*
const onMapClick = (e) => {
  setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    ]);
    setCoordsArray(coordsArray+"{ lat: "+e.latLng.lat()+", lng: "+e.latLng.lng()+"},");
  };
  */


	const mapRef = useRef();
	const autocompleteRef = useRef();

  const styles = {
    formContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        paddingBottom: '10px'
    },
    buttonGroup: {
        display: 'flex',
        gap: '10px'
    },
    buttonAlqventa: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        fontSize: '16px',
        borderRadius: '5px',
        transition: 'background-color 0.3s'
    },
    buttonAlqventaPrimary: {
        backgroundColor: '#2EBF91' // Primary button color
    },
    buttonAlqventaPrimaryHover: {
        backgroundColor: '#0056b3' // Darker color on hover
    },
    buttonAlqventaSecondary: {
        backgroundColor: '#6c757d' // Secondary button color
    },
    buttonAlqventaSecondaryHover: {
        backgroundColor: '#5a6268' // Darker color on hover
    },
    icon: {
        marginRight: '5px'
    },
    label: {
        fontWeight: 'bold',
        padding: '10px',
        color: 'white'
    }
};

const stylesForm1 = `
  .form-container {
    padding: 20px;
    border-radius: 8px;
    color: white;
  }
  .form-label {
    font-weight: bold;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
  }
  .form-label i {
    padding-right: 12px;
  }
  .form-control {
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  .form-group1 {
    margin-bottom: 1rem;
  }
  .form-group1.hidden {
    display: none;
  }
`;

	const containerStyle = {
        width: "95%",
        height: "auto",
        margin:"auto",
        paddingTop: "10px",
        paddingRight:"0px",
        borderRadius:"20px",
        marginTop:"8%"
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mapContainerStyle = {
    //width: '95%',
    width: isMobile ? '100%' : '95%',
    height: isMobile ? '50vh' : '100vh',
    margin: 'auto',
    paddingTop: '10px',
    paddingRight: '0px',
    borderRadius: '20px',
    marginTop: '8%'
  };

	const inputStyle = {
		boxSizing: 'border-box',
		border: '1px solid transparent',
		width: '240px',
		height: '38px',
		padding: '0 12px',
		borderRadius: '3px',
		boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
		fontSize: '14px',
		outline: 'none',
		textOverflow: 'ellipses',
		position: 'absolute',
		right: '8%',
		top: '11px',
		marginLeft: '-120px'
	}

    const center = {
        lat: 28.626137,
        lng: 79.821603,
    }

	const onLoadMap = (map) => {
		mapRef.current = map
	}

	const onLoadAutocomplete = (autocomplete) => {
		autocompleteRef.current = autocomplete
	}

  const checkArea = (zone) => {
  }

  const TasarBtn = () => {
    if(!loadingPrice){
      return(
        <div style={{color:'white'}}>Valuar</div>
      )
    }else{
      return(
        <div><RotatingLines
        visible={true}
        height="45"
        width="45"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
        /></div>
      )
    }
  }

	const handlePlaceChanged = async ()  => {
		const { geometry, formatted_address, results } = await autocompleteRef.current.getPlace();
    var zone = await JSON.stringify(JSON.stringify(autocompleteRef.current.getPlace().address_components));

    setZone(zone);
    setAddressD(formatted_address);
    send(formatted_address);
		const bounds = new window.google.maps.LatLngBounds();
		if (geometry.viewport) {
			bounds.union(geometry.viewport);
		} else {
			bounds.extend(geometry.location);
		}
		mapRef.current.fitBounds(bounds);
    setMarker(geometry.location);
    setAddress(geometry.location);
  }

  const containsLoc = [];

	return (
    <>
    <CustomNavbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} username={username} amount={credits} onBuyCredits={handleShowBuyCredits} usertype={userType}/>
    <BuyCredits show={showBuyCredits} onHide={handleCloseBuyCredits} />
    
		<div className="row" style={{margin:'auto',width:'100%',padding:'0px',backgroundColor:''}}>
        {
				isLoaded
				&&
        <div className="row" style={{margin:'auto',width:'100%',padding:'0px'}}>
        <div style={{padding:'20px',textAlign:'center'}}>
        <h2 style={{textAlign:'center'}}>GBA y CABA</h2>
        </div>
         
        <div className="row mainMenu" style={{margin:'auto',backgroundColor:'#2355c6'}}>
        <div className="col-lg-6">
        <Autocomplete
						onLoad={onLoadAutocomplete}
						onPlaceChanged={handlePlaceChanged}
					>
            <div style={{margin:'center',textAlign:'center',padding:'20px',width:'100%'}}>
            <label for="" style={{fontWeight:"bold",padding:'10px',color:'white',width:'100%'}}>Ingresar la direccion</label>
            <input
            style={{padding:'10px',width:'100%',borderRadius:'10px',outline:'none',borderColor:'lightblue', borderStyle:'solid'}}
							type='text'
							placeholder='Ingresa tu direccion'
						/>
            </div>
						
					</Autocomplete>
          <div className="row" style={{paddingBottom:'10px'}}>

          
            <div className="col-12 col-sm-12 col-lg-6">
            
                <label htmlFor="operacion" style={styles.label}>
                    Operacion
                </label>
                <div style={styles.formContainer}>
                <div style={styles.buttonGroup}>
                    <button
                        id="rent-button"
                        style={{ ...styles.buttonAlqventa, ...(operation === 'alquiler' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setOperation('alquiler')}
                    >
                        <i className="fas fa-house-user" style={styles.icon}></i> Alquiler
                    </button>
                    <button
                        id="sale-button"
                        style={{ ...styles.buttonAlqventa, ...(operation === 'venta' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setOperation('venta')}
                    >
                        <i className="fas fa-home" style={styles.icon}></i> Venta
                    </button>
                </div>
                </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-6">
            
                <label htmlFor="propiedad" style={styles.label}>
                    Tipo de Propiedad
                </label>
                <div style={styles.formContainer}>
                <div style={styles.buttonGroup}>
                    <button
                        id="department-button"
                        style={{ ...styles.buttonAlqventa, ...(property === 'departamento' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setProperty('departamento')}
                    >
                        <i className="fas fa-building" style={styles.icon}></i> Departmento
                    </button>
                    <button
                        id="house-button"
                        style={{ ...styles.buttonAlqventa, ...(property === 'casa' ? styles.buttonAlqventaPrimary : styles.buttonAlqventaSecondary) }}
                        onClick={() => setProperty('casa')}
                    >
                        <i className="fas fa-home" style={styles.icon}></i> Casa
                    </button>
                </div>
                </div>
            </div>
         
            
          {
            operation==='alquiler' ? 
            <>
   <div className="form-container">
    <div className="row">
    <div className="col-lg-6 form-group1">
    <style>{stylesForm1}</style>
          <label htmlFor="ambientes" className="form-label">
            <FontAwesomeIcon icon={faHome} /> <b style={{paddingLeft:'8px'}}>Ambientes</b>
          </label>
          <input 
            type="text" 
            id="ambientes" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={amb} 
            onChange={(e) => handleChange(e, "amb")} 
          />
        </div>
        <div className="col-lg-6 form-group1">
          <label htmlFor="cochera" className="form-label">
            <FontAwesomeIcon icon={faCar} /> <b style={{paddingLeft:'8px'}}>Cochera/s (cantidad)</b>
          </label>
          <input 
            type="text" 
            id="cochera" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            placeholder="0" 
            value={garage || ''} 
            onChange={(e) => handleChange(e, "garage")} 
          />
        </div>
        <div className="col-lg-6 form-group1">
        <label htmlFor="antiguedad" className="form-label">
            <FontAwesomeIcon icon={faCalendarAlt} /> <b style={{paddingLeft:'8px'}}>Antigüedad (años)</b>
          </label>
          <input 
            type="text" 
            id="antiguedad" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={antiguedad} 
            onChange={(e) => handleChange(e, "antiguedad")} 
          />
        </div>
        </div>
        </div>
  </>
          :
          <>
          <div className="form-container">
      <style>{stylesForm1}</style>
      <div className="row">
        <div className="col-lg-6 form-group1">
          <label htmlFor="ambientes" className="form-label">
            <FontAwesomeIcon icon={faHome} /> <b style={{paddingLeft:'8px'}}>Ambientes</b>
          </label>
          <input 
            type="text" 
            id="ambientes" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={amb} 
            onChange={(e) => handleChange(e, "amb")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="cochera" className="form-label">
            <FontAwesomeIcon icon={faCar} /> <b style={{paddingLeft:'8px'}}>Cochera/s (cantidad)</b>
          </label>
          <input 
            type="text" 
            id="cochera" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            placeholder="0" 
            value={garage || ''} 
            onChange={(e) => handleChange(e, "garage")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="sc" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Cubierta m²</b>
          </label>
          <input 
            type="text" 
            id="sc" 
            className="form-control" 
            aria-describedby="sc" 
            placeholder="0" 
            value={sc || ''} 
            onChange={(e) => handleChange(e, "sc")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="st" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Semicubierta m²</b>
          </label>
          <input 
            type="text" 
            id="st" 
            className="form-control" 
            aria-describedby="st" 
            placeholder="0" 
            value={st || ''} 
            onChange={(e) => handleChange(e, "st")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="sd" className="form-label">
            <FontAwesomeIcon icon={faRuler} /> <b style={{paddingLeft:'8px'}}>Superficie Descubierta m²</b>
          </label>
          <input 
            type="text" 
            id="sd" 
            className="form-control" 
            aria-describedby="sd" 
            placeholder="0" 
            value={sd || ''} 
            onChange={(e) => handleChange(e, "sd")} 
          />
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="estado" className="form-label">
            <FontAwesomeIcon icon={faCogs} /> <b style={{paddingLeft:'8px'}}>Estado</b>
          </label>
          <select 
            id="estado" 
            className="form-control" 
            aria-describedby="estado" 
            defaultValue={"Estrenar"} 
            onChange={(e) => setCondition(e.target.value)}
          >
            <option value={"Estrenar"}>Estrenar</option>
            <option value={"Muy bueno"}>Muy bueno</option>
            <option value={"Bueno"}>Bueno</option>
            <option value={"Regular"}>Regular</option>
            <option value={"Malo"}>Malo</option>
            <option value={"Restauracion"}>Restauracion</option>
            <option value={"Demolicion"}>Demolicion</option>
          </select>
        </div>

        <div className="col-lg-6 form-group1">
          <label htmlFor="antiguedad" className="form-label">
            <FontAwesomeIcon icon={faCalendarAlt} /> <b style={{paddingLeft:'8px'}}>Antigüedad (años)</b>
          </label>
          <input 
            type="text" 
            id="antiguedad" 
            className="form-control" 
            aria-describedby="helpAccountId" 
            value={antiguedad} 
            onChange={(e) => handleChange(e, "antiguedad")} 
          />
        </div>

        
      </div>
    </div>
            
          </>
          }
          </div>
          
          <label for="amenities" style={{fontWeight:"bold",padding:'10px',color:'white',display:'none'}}>Amenities</label>
          <div className="row" style={{margin:'auto',textAlign:'center'}}>
          <div style={{display:'none'}}>
          <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              Parrilla <input type="checkbox" onChange={(e) => {setBarbecue(!barbecue)}} />
            </div>
            <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              Pileta <input type="checkbox" onChange={(e) => {setPool(!pool)}} />
            </div>
            <div className={"col-lg-4"} style={{color:'white',display:'hidden'}}>
              SUM <input type="checkbox" onChange={(e) => {setSum(!sum)}} />
            </div>
            </div>
           
            
            <div className="col-lg-12"><button className={"tasarBtn"} onClick={e => get_price()} disabled={!address || loadingPrice} ><TasarBtn /></button></div>
            
          </div>
          
        </div>
        <div className="col-lg-6" id={'mapDiv'}>
        
          <GoogleMap
					mapContainerStyle={mapContainerStyle}
					center={marker}
					onLoad={onLoadMap}
          //onClick={onMapClick}
					zoom={15}
				>
            <Marker 
              position={marker} 
            />

            {markers.map((marker) => (
            <Marker 
              position={{ 
              lat: marker.lat,
              lng: marker.lng 
            }} />
            ))}
          
				</GoogleMap>
        {coordsArray}
        </div>
        
        </div>
        {
  gotPrice && operation === 'alquiler' ? (
    <div id={'result'} className="row mainMenu" style={{textAlign:'center',margin:'auto',borderColor:'#0e4d92',borderStyle:'solid',marginTop:'20px',background:'none',backgroundColor:"white"}}>
        <div>{addressD}</div>
        <div className="col-lg-12">
          <div style={{textAlign:'center',margin:'auto'}}>
            <h3>Valor aproximado <b style={{color:'blue'}}>AR$ {valorPropUno}</b>
            </h3>
            <h3>Valor aproximado <b style={{color:'green'}}>U$D {valorPropDos}</b></h3>
          </div>
          <div className="col-lg-12" style={{textAlign:'center',margin:'auto',padding:'10px',fontStyle:'italic'}} onClick={request_wpp}>
          </div>
        </div> 
      </div> // Render an empty div if gotPrice is true and operation is 'Alquiler'
  ) : (
    gotPrice && (
        <div id={'result'} className="row mainMenu" style={{textAlign:'center',margin:'auto',borderColor:'#0e4d92',borderStyle:'solid',marginTop:'20px',background:'none',backgroundColor:"white"}}>
          <div>{addressD}</div>
        <div className="col-lg-12">
            <div style={{textAlign:'center',margin:'auto'}}>
              <h3>Valor aproximado entre  <b style={{color:'green'}}><CurrencyFormat value={valorPropUno} displayType={'text'} thousandSeparator={true} prefix={'U$'} decimalScale={0} /></b> y <b style={{color:'green'}}><CurrencyFormat value={valorPropDos} displayType={'text'} thousandSeparator={true} prefix={'U$'} decimalScale={0} /></b>
              </h3>
                      </div>
                      <PDF
                        name={username}
                        lastName={userLname}
                        address={addressD}
                        msc={st}
                        md={sd}
                        mc={sc}
                        garage={garage}
                        antiguedad={antiguedad}
                        condicion={condition}
                        price1={valorPropUno}
                        price2={valorPropDos}
                        credits={credits}
                        setCreds={setCredits}
                      />
        </div>
        </div>
        )
      )
        }
        <div className={'row'}>
        <div className="col-lg-12" style={{textAlign:'center',margin:'auto', padding:'20px'}}>
          <b>info@tasacionesya.com - <a href="/terminos-y-condiciones">Terminos y Condiciones</a></b>
          <div>Copyright © tasacionesya.com 2024</div>
        </div>
        </div>
        </div>
				
			}
		</div>
    </>
	);

}

export default Vendor;
