import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from '../img/tasaciones_logo.png'; // Update with your logo path
import { FaUserCircle, FaBars, FaTimes, FaShoppingCart, FaSignOutAlt, FaCoins } from 'react-icons/fa'; // Import icons
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios';
import { verifyToken } from '../utils/auth';
import BuyCredits from '../components/BuyCreditsModal.js';


const primaryColor = '#8360C3'; // Purple color for hamburger icon and active states
const secondaryColor = '#2EBF91'; // Green color for menu items

const navbarStyle = {
  backgroundColor: 'white',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const textFieldStyle = {
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
};

const hamburgerMenuStyle = {
  fontSize: '24px',
  cursor: 'pointer',
  color: primaryColor,
};

const menuOpenStyle = {
  position: 'absolute',
  top: '60px',
  right: '0',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '10px',
  borderRadius: '4px',
  zIndex: '1000',
  width: '200px',
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  textDecoration: 'none',
  color: secondaryColor,
};

const menuIconStyle = {
  marginRight: '8px',
};

const logoStyle = {
  width: '120px',
  height: 'auto',
};

const logoStyleMobile = {
  width: '80px',
  height: 'auto',
};

const creditsIconStyle = {
  fontSize: '24px',
  marginRight: '12px',
  color: secondaryColor,
};

const desktopNavStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
};

const mobileNavStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const CustomNavbar = ({ amount = null }) => {  // Default amount to null if not provided
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(null);
  const [userType, setUserType] = useState('');
  const [showBuyCredits, setShowBuyCredits] = useState(false);
  const [userLname, setUserLname] = useState('');

  const handleShowBuyCredits = () => setShowBuyCredits(true);
  const handleCloseBuyCredits = () => setShowBuyCredits(false);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    setIsAuthenticated(false);
    navigate('/');
  };

  useEffect(() => {
    const checkGetUser = async () => {
      try {
        const authenticated = await verifyToken();
        if (!authenticated) {
          navigate('/');
        } else {
          setIsAuthenticated(true);

          const getUser = async () => {
            try {
              const response = await axios.post('https://tasacionesya.com:8099/api/get_user', {}, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                },
              });

              if (response.status === 200) {
                setUsername(response.data.username);
                setUserLname(response.data.userLname);
                setUserType(response.data.userType);
                setCredits(response.data.credits);
              } else {
                console.error('Error fetching user:', response.statusText);
              }
            } catch (error) {
              console.error('Error fetching user:', error);
            }
          };

          await getUser();
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        navigate('/');
      }
    };

    checkGetUser();
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Navbar bg='light' variant='light' expand='lg' style={navbarStyle}>
      <BuyCredits show={showBuyCredits} onHide={handleCloseBuyCredits} />
      <Container fluid>
        {!isMobile && (
          <div style={desktopNavStyle}>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', paddingLeft: '30px' }}>
              <div style={textFieldStyle}>
                <FaCoins style={creditsIconStyle} />
                {/* Use amount if available, otherwise use credits */}
                {amount !== null ? amount : credits}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
              <Navbar.Brand as={Link} to='/'>
                <img src={logo} alt='Logo' style={logoStyle} />
              </Navbar.Brand>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'flex-end', paddingRight: '30px' }}>
              {isAuthenticated && (
                <NavDropdown
                  title={<><FaUserCircle style={{ fontSize: '24px', marginRight: '8px' }} /> {username}</>}
                  id='user-dropdown'
                  align='end'
                >
                  <NavDropdown.Item onClick={handleShowBuyCredits} style={{ color: secondaryColor }}>
                    <FaShoppingCart style={menuIconStyle} />
                    Comprar créditos
                  </NavDropdown.Item>
                  {userType === 'Admin' && (
                    <NavDropdown.Item as={Link} to='/admin' style={{ color: secondaryColor }}>
                      <FaUserCircle style={menuIconStyle} />
                      Admin
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={handleLogout} style={{ color: secondaryColor }}>
                    <FaSignOutAlt style={menuIconStyle} />
                    Cerrar sesión
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                onClick={toggleMenu}
                style={hamburgerMenuStyle}
              >
                {isMenuOpen ? <FaTimes /> : <FaBars />}
              </Navbar.Toggle>
            </div>
          </div>
        )}
        {isMobile && (
          <div style={mobileNavStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Navbar.Brand as={Link} to='/'>
                <img src={logo} alt='Logo' style={logoStyleMobile} />
              </Navbar.Brand>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={textFieldStyle}>
                <FaCoins style={creditsIconStyle} />
                {/* Use amount if available, otherwise use credits */}
                {amount !== null ? amount : credits}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                onClick={toggleMenu}
                style={hamburgerMenuStyle}
              >
                {isMenuOpen ? <FaTimes /> : <FaBars />}
              </Navbar.Toggle>
            </div>
          </div>
        )}
        <Navbar.Collapse id='basic-navbar-nav'>
          {isMobile && isMenuOpen && (
            <div style={menuOpenStyle} className="d-lg-none">
              {isAuthenticated ? (
                <>
                  <div onClick={handleShowBuyCredits} style={menuItemStyle}>
                    <FaShoppingCart style={menuIconStyle} />
                    Comprar créditos
                  </div>
                  {userType === 'Admin' && (
                    <Link to='/admin' style={menuItemStyle}>
                      <FaUserCircle style={menuIconStyle} />
                      Admin
                    </Link>
                  )}
                  <div onClick={handleLogout} style={menuItemStyle}>
                    <FaSignOutAlt style={menuIconStyle} />
                    Cerrar sesión
                  </div>
                </>
              ) : (
                <Link to='/login' style={menuItemStyle}>Login</Link>
              )}
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
