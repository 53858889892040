import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';


// Test credentials
//const mp_pk = "APP_USR-f6f5efd3-f196-44f0-82dc-9991a44c3a6d";

// Production credentials (comment/uncomment as needed)
const mp_pk = "APP_USR-d8006a2b-c81a-4bac-8bf6-b946cf695307";

initMercadoPago(mp_pk);

const calculateDiscountedPrice = (price, discount) => {
  return discount > 0 ? price - (price * (discount / 100)) : price;
};

const formatPrice = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

const url = 'https://tasacionesya.com';

const CreditBox = React.memo(({ pack, isSelected, onSelect, isDisabled }) => {
  const creditBoxStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${isSelected ? '#2EBF91' : '#e0e0e0'}`, // Green border for selected
    borderRadius: '10px',
    padding: '0.75rem',
    transition: 'box-shadow 0.3s, transform 0.3s, background-color 0.3s',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    height: '180px',
    boxSizing: 'border-box',
    textAlign: 'center',
    marginBottom: '0.75rem',
    opacity: isDisabled ? 0.5 : 1,
    position: 'relative',
    maxWidth: '100%',
    backgroundColor: isSelected ? '#e0f7f4' : '#ffffff',
  };

  const creditBoxHoverStyles = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
    transform: 'scale(1.02)',
  };

  const priceButtonStyles = {
    backgroundColor: '#2EBF91', // Teal background
    borderColor: '#2EBF91',
    color: '#ffffff',
    fontWeight: '600',
    borderRadius: '8px',
    padding: '0.5rem',
    fontSize: '1rem',
    transition: 'background-color 0.3s, transform 0.3s',
    border: 'none',
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const discountBadgeStyles = {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: '#dc3545', // Red badge color
    color: 'white',
    padding: '0.25rem 0.5rem',
    borderRadius: '5px',
    fontSize: '0.875rem',
    transform: 'translate(10%, -30%)',
  };

  const originalPrice = pack.Price;
  const discountedPrice = calculateDiscountedPrice(originalPrice, pack.Discount);

  return (
    <div
      style={creditBoxStyles}
      onMouseEnter={(e) => !isDisabled && (e.currentTarget.style.boxShadow = creditBoxHoverStyles.boxShadow)}
      onMouseLeave={(e) => e.currentTarget.style.boxShadow = ''}
      onClick={() => !isDisabled && onSelect(pack)}
    >
      {pack.Discount > 0 && (
        <div style={discountBadgeStyles}>
          {pack.Discount}% OFF
        </div>
      )}
      <div>
        <h5 style={{ color: '#8360C3' }}>{pack.Title}</h5>
        <p style={{ color: '#333333', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FontAwesomeIcon icon={faCoins} style={{ marginRight: '0.5rem', color: '#2EBF91' }} />
          {pack.Credits} Créditos
        </p>
      </div>
      <div style={priceButtonStyles}>
        {pack.Discount > 0 && (
          <span style={{ textDecoration: 'line-through', fontSize: '1.5rem', color: 'red', marginBottom: '0.5rem' }}>
            ${formatPrice(originalPrice)}
          </span>
        )}
        <span style={{ fontSize: '1.25rem', color: '#ffffff' }}>
          ${formatPrice(discountedPrice)}
        </span>
      </div>
    </div>
  );
}, (prevProps, nextProps) => prevProps.isSelected === nextProps.isSelected && prevProps.isDisabled === nextProps.isDisabled);

const BuyCredits = ({ show, onHide }) => {
  const [preferenceId, setPreferenceId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creditPackages, setCreditPackages] = useState([]);
  const walletRef = useRef(null);

  const fetchPrices = useCallback(async () => {
    try {
      const response = await axios.post(url + ':8099/api/get_prices_tasaciones', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      const packages = response.data;
      setCreditPackages(packages);

      // Automatically select the first package and create the MercadoPago preference
      if (packages.length > 0) {
        const firstPackage = packages[0];
        setSelectedProduct(firstPackage.Title);
        await createPreference(firstPackage);
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  }, []);

  useEffect(() => {
    fetchPrices();
    initMercadoPago(mp_pk, { locale: 'es-AR' });
  }, [fetchPrices]);

  const createPreference = useCallback(async (pack) => {
    if (loading) return;

    try {
      setLoading(true);
      
      const response = await axios.post(url + ':8099/create_preference', {
        items: [
          { title: pack.Title, unit_price: calculateDiscountedPrice(pack.Price, pack.Discount), quantity: 1 },
        ],
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setPreferenceId(response.data.preferenceId);
    } catch (error) {
      console.error('Error creating preference:', error);
    } finally {
      setLoading(false);
      // Scroll to the bottom of the modal
      setTimeout(() => {
        if (walletRef.current) {
          const modalBody = walletRef.current.closest('.modal-body');
          if (modalBody) {
            walletRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            modalBody.scrollTop = modalBody.scrollHeight; // Ensure modal body scrolls to the bottom
          }
        }
      }, 300); // Adjust the delay as needed
    }
  }, [loading]);

  const handleSelect = useCallback(async (pack) => {
    if (loading) return;

    try {
      setSelectedProduct(pack.Title);
      await createPreference(pack);
    } catch (error) {
      console.error('Error creating preference:', error);
    }
  }, [loading, createPreference]);

  const memoizedWallet = useMemo(() => (
    preferenceId && (
      <div ref={walletRef}>
        <Wallet initialization={{ preferenceId }} />
      </div>
    )
  ), [preferenceId]);

  return (
    <Modal show={show} onHide={onHide} centered style={{ borderRadius: '12px', border: 'none', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
      <Modal.Body style={{ padding: '20px', position: 'relative', overflowY: 'auto', maxHeight: '80vh', overflowX: 'hidden' }}>
        <Button
          variant="link"
          onClick={onHide}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '1.5rem',
            color: '#8360C3',
            padding: '0',
            border: 'none',
            textDecoration: 'none',
            textTransform: 'uppercase',
          }}
        >
          &times;
        </Button>
        <h5 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '0.25rem', textAlign: 'center', color: '#8360C3' }}>Compra de Créditos</h5>
        <p style={{ color: '#2EBF91', fontSize: '0.9rem', marginBottom: '2rem', textAlign: 'center' }}>
          Elige tu paquete de créditos para realizar la compra.
        </p>
        <div style={{textAlign:'center'}}><p>1 credito = 1 valuacion</p></div>
        <Row xs={1} sm={2} className="g-4">
          {creditPackages.map((pack) => (
            <Col key={pack._id}>
              <CreditBox
                pack={pack}
                isSelected={selectedProduct === pack.Title}
                onSelect={handleSelect}
                isDisabled={loading}
              />
            </Col>
          ))}
        </Row>
        <Row style={{ minHeight: '100px' }}>
          <Col style={{ textAlign: 'center' }}>
            {loading && <Spinner animation="border" variant="primary" />}
            {memoizedWallet}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BuyCredits;
