import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import logo from '../img/tasaciones_logo.png'; 
import axios from 'axios';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Helvetica',
    fontSize: 12,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
    paddingBottom: 10,
    borderBottom: '2px solid #E0E0E0',
  },
  logo: {
    width: 120,
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#333333',
  },
  section: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#F7F7F7',
  },
  text: {
    marginBottom: 12,
    lineHeight: 1.6,
    color: '#333333',
  },
  boldText: {
    fontWeight: 'bold',
  },
  signature: {
    marginTop: 40,
  },
  contactInfo: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 30,
    paddingTop: 10,
    borderTop: '2px solid #E0E0E0',
  },
  contactItem: {
    marginRight: 10,
    fontSize: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    backgroundColor: '#8360C3',
    color: 'white',
    textAlign: 'center',
    paddingTop: 15,
    fontSize: 10,
  },
  buttonEnabled: {
    marginTop: 30,
    padding: 10,
    backgroundColor: '#8360C3',
    color: 'white',
    textAlign: 'center',
    borderRadius: 5,
    cursor: 'pointer',
  },
  buttonDisabled: {
    marginTop: 30,
    padding: 10,
    backgroundColor: '#E0E0E0',
    color: '#A0A0A0',
    textAlign: 'center',
    borderRadius: 5,
    cursor: 'not-allowed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 5,
  }
});

const getFormattedDate = () => {
  const options = { 
    weekday: 'long', 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric' 
  };
  const now = new Date();
  return new Intl.DateTimeFormat('es-ES', options).format(now);
};

// Create Document Component
const TasacionPDF = ({ name, lastName, address, mc, msc, md, condicion, antiguedad, garage, price1, price2, credits, setCreds }) => {
  const formattedDate = getFormattedDate();
  // Format numbers
  const format_num_1 = new Intl.NumberFormat('es-ES', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
  }).format(price1);

  const format_num_2 = new Intl.NumberFormat('es-ES', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0 
  }).format(price2);

  const handlePayAndDownload = async () => {
    //setLoadingPayment(true);
    //setPaymentError(null);
    
    try {
      const response = await axios.post(
        'https://tasacionesya.com:8099/api/pay_credits_report',
        {},
        { headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}` // Pass the token in the Authorization header
        } }
      );
      
      if (response.status === 200) {
        //setCreditsUpdated(response.data.credits);
        setCreds(response.data.credits);
        //console.log(response.data.credits);
      }
    } catch (error) {
      //setPaymentError('Error al cobrar los créditos');
    } finally {
      //setLoadingPayment(false);
    }
  };

  return (
    <div>
      {credits > 0 ? (
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                  <Image src={logo} style={styles.logo} />
                  <Text style={styles.text}>{formattedDate}</Text>
                </View>
                <Text style={styles.text}>Ref.: Valoración por VENTA</Text>
                <Text style={styles.title}>Estimado/a {name} {lastName}</Text>
                <View style={styles.section}>
                  <Text style={styles.text}>
                    Inmueble ubicado en {address}.
                  </Text>
                  <Text style={[styles.text, styles.boldText]}>
                    De Nuestra consideración:
                  </Text>
                  <Text style={styles.text}>
                    Por medio de la presente, nos dirigimos a Ud. a efectos de informarle que hemos realizado 
                    la valuación de la propiedad de referencia. De acuerdo con su ubicación, superficie informada 
                    por el solicitante {name} {lastName} de {mc} mts2 cubiertos, {msc} mts2 Semicubiertos, {md} mts2 descubiertos; Cochera/s {garage}, estado actual del inmueble {condicion}, antigüedad {antiguedad}, distribución y valores de plaza, 
                    se han utilizado los siguientes métodos de análisis:
                  </Text>
                  <Text style={styles.text}>- Valor físico del Inmueble.</Text>
                  <Text style={styles.text}>
                    Siendo su valor de valuacion por venta a la fecha de Dólares Estadounidenses .
                  </Text>
                  <Text style={styles.text}>
                  Entre U$D{format_num_1} y U$D{format_num_2}
                  </Text>
                  <Text style={styles.text}>
                    Sin otro particular y quedando a entera disposición, lo saluda muy atentamente.
                  </Text>
                </View>
                <View style={styles.contactInfo}>
                  <Text style={styles.contactItem}>info@tasacionesya.com</Text>
                  <Text style={styles.contactItem}>www.tasacionesya.com</Text>
                </View>
                <View style={styles.footer}>
                  <Text>Tasaciones YA - Confianza y Seguridad en valuaciones</Text>
                </View>
              </Page>
            </Document>
          }
          fileName="valuacion.pdf"
        >
          {({ loading }) => (
            <div style={styles.buttonEnabled} onClick={handlePayAndDownload}>
              {loading ? 'Generando informe...' : 'Descargar informe (1 crédito)'}
            </div>
          )}
        </PDFDownloadLink>
      ) : (
        <div style={styles.buttonDisabled}>
          <span role="img" aria-label="locked" style={styles.icon}>🔒</span>
          Descargar informe (0 créditos)
        </div>
      )}
    </div>
  );
};

export default TasacionPDF;
