import React, { useState } from 'react';
import { Modal, Button, Form, Spinner, Card } from 'react-bootstrap';
import axios from 'axios';
import { FaCheckCircle, FaMapMarkerAlt, FaFilePdf, FaPhoneAlt } from 'react-icons/fa'; // Icons for services

// Define your styles including primary and secondary colors
const stylesForm = `
  :root {
    --primary-color: #2EBF91; /* Primary color */
    --secondary-color: #6c757d; /* Secondary color */
  }
  
  .form-label-custom {
    color: var(--primary-color);
  }

  .form-control-error {
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }

  .card-option {
    margin-bottom: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px solid;
    border-radius: 8px;
  }

  .card-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .card-primary {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }

  .card-secondary {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }

  .card-title {
    font-weight: bold;
  }

  .card-text {
    color: #333;
  }
`;

const ModalCredits = ({ status, setModalC, url, found, valorProp1, valorProp2 }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    tipoUsuario: '',
    version: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [step, setStep] = useState(1); // Default to 1 for options view
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    setSuccess(false);

    let validationErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'tipoUsuario') {
        validationErrors[key] = 'Este campo es obligatorio';
      }
    });
    if (!termsAccepted) {
      validationErrors.terms = 'Debes aceptar los términos y condiciones.';
    }
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${url}:8099/api/register`, formData);

      if (response.data === 'User created') {
        setSuccess(true);
      } else if (response.data === 'Usuario ya registrado') {
        setError({ correo: 'Usuario ya registrado. Por favor, utilice otro correo electrónico.' });
      } else {
        setError({ general: 'Hubo un problema al enviar el formulario. Por favor, intente de nuevo.' });
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      setError({ general: 'Hubo un problema al enviar el formulario. Por favor, intente de nuevo.' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setModalC(false);
    setSuccess(false);
    setStep(1); // Reset to options view when closing
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFormData({
      ...formData,
      version: option === 'Gratis' ? 'gratis' : 'pago',
    });
    setStep(2); // Proceed to the form view after selecting an option
  };

  return (
    <Modal show={status} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {step === 1
            ? 'Seleccione una opción'
            : success
            ? 'Registro Exitoso'
            : found
            ? selectedOption === 'Gratis'
              ? 'Regístrate y obtene tu primer valuación GRATIS'
              : 'Regístrate para valuar tu propiedad'
            : 'Por el momento no cubrimos la zona ingresada, pero no te preocupes.'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 ? (
          <div className="d-flex flex-column align-items-center">
            <style>{stylesForm}</style>

            {/* Gratis option */}
            <Card
              style={{ width: '100%', cursor: 'pointer' }}
              className="text-center card-option card-primary"
              onClick={() => {
                window.location.href = 'tel:+5491151048360';
              }}
            >
              <Card.Body>
                <Card.Title>Asesoramiento GRATIS</Card.Title>
                <Card.Text>
                  Si eliges esta opción, puedes contactarte directamente con un martillero que te brindará asistencia personalizada y una
                  valuación más precisa.
                </Card.Text>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: '#2EBF91',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    window.location.href = 'tel:+5491151048360';
                  }}
                >
                  <FaPhoneAlt /> Contactarse con un martillero
                </Button>
              </Card.Body>
            </Card>

            {/* Pago option */}
            <Card
              style={{ width: '100%', cursor: 'pointer' }}
              className="text-center card-option card-secondary"
              onClick={() => handleOptionClick('Pago')}
            >
              <Card.Body style={{ position: 'relative' }}>
  <span
    style={{
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '16px',
      padding: '5px 10px',
      borderRadius: '50px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      zIndex: '1',
    }}
  >
    86% OFF
  </span>
  <Card.Title>
    <span style={{ textDecoration: 'line-through', color: 'red' }}>$7.000</span>
    <span style={{ marginLeft: '10px', fontWeight: 'bold', color: 'green' }}>
      $980
    </span>
  </Card.Title>
  <Card.Text>
    Obtene tu valuación y reporte al instante con nuestro valuador online.
  </Card.Text>
  <div
    style={{
      marginTop: '10px',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#ff9800', // Orange for visibility
      textAlign: 'center',
      textTransform: 'uppercase',
    }}
  >
    SOLO PARA GBA Y CABA
  </div>
</Card.Body>




            </Card>
          </div>
        ) : success ? (
          <p>
            Le hemos enviado un correo electrónico para ingresar en su cuenta. Si no lo recibió, por favor revise la casilla de correo no
            deseado. Gracias.
          </p>
        ) : (
          <>
            <style>{stylesForm}</style>
            <div className="items-section">
              <p className="item-title">Servicios Ofrecidos</p>
              <p>
                <FaCheckCircle /> Tu valuación en tiempo real
              </p>
              <p>
                <FaMapMarkerAlt /> Si no cubrimos la zona, uno de nuestros martilleros te enviará la valuación hecha manualmente a tu correo
              </p>
              <p>
                <FaFilePdf /> Reporte en PDF para descargar
              </p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formNombre">
                <Form.Label className="form-label-custom">Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introduce tu nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  className={error.nombre ? 'form-control-error' : ''}
                  required
                />
                {error.nombre && <Form.Text className="text-danger">{error.nombre}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formApellido">
                <Form.Label className="form-label-custom">Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introduce tu apellido"
                  name="apellido"
                  value={formData.apellido}
                  onChange={handleChange}
                  className={error.apellido ? 'form-control-error' : ''}
                  required
                />
                {error.apellido && <Form.Text className="text-danger">{error.apellido}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formCorreo">
                <Form.Label className="form-label-custom">Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Introduce tu correo electrónico"
                  name="correo"
                  value={formData.correo}
                  onChange={handleChange}
                  className={error.correo ? 'form-control-error' : ''}
                  required
                />
                {error.correo && <Form.Text className="text-danger">{error.correo}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formTelefono">
                <Form.Label className="form-label-custom">Teléfono</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Introduce tu teléfono"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  className={error.telefono ? 'form-control-error' : ''}
                  required
                />
                {error.telefono && <Form.Text className="text-danger">{error.telefono}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formTipoUsuario">
                <Form.Label className="form-label-custom">Tipo de Usuario</Form.Label>
                <Form.Control
                  as="select"
                  name="tipoUsuario"
                  value={formData.tipoUsuario}
                  onChange={handleChange}
                  className={error.tipoUsuario ? 'form-control-error' : ''}
                  required
                >
                  <option value="">Seleccione...</option>
                  <option value="individual">Individual</option>
                  <option value="empresa">Empresa</option>
                </Form.Control>
                {error.tipoUsuario && <Form.Text className="text-danger">{error.tipoUsuario}</Form.Text>}
              </Form.Group>

              <Form.Group controlId="formTerms">
                <Form.Check
                  type="checkbox"
                  label="Acepto los términos y condiciones"
                  checked={termsAccepted}
                  onChange={handleCheckboxChange}
                  className={error.terms ? 'text-danger' : ''}
                />
                {error.terms && <Form.Text className="text-danger">{error.terms}</Form.Text>}
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Enviar'}
              </Button>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCredits;
